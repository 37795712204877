
.number {
    position: relative;
    display: inline-block;
    width: 2.656vw;
    height: 2.656vw;
}

.number.desktop {
    margin-right: 1.302vw;
}

.number.mobile {
    width: 10.019vw;/* *15/16 */
    height: 10.019vw;/* *15/16 */
    margin-right: 1.669vw;/* *15/16 */
    margin-left: 0.715vw;/* *15/16 */
}

.num {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #5B5B5B;
    font-family: Inter;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 700;
    line-height: 2.656vw;
    z-index: 1;
    /* Ensure the text is above the blur */
}
.num.mobile {
    font-size: 3.817vw;/* *15/16 */
    line-height: 10.019vw;/* *15/16 */
}

.num::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 50%;
    filter: blur(0.393vw);
    opacity: 0.49;
    z-index: -1; /* Ensure the blur effect is behind the text */
}

.num.mobile::before {
    filter: blur(1.92vw);
}


.text {
    margin: 0;
    text-align: start; 
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-size: 0.938vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
}

.text.mobile {
    font-size:3.817vw; /* *15/16 */
    max-width:78.371vw;
    max-width: 74.427vw;
}
