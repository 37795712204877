.footer{
    display: flex;
    justify-content: center;
    column-gap: 5.4688vw;
    background: #1F1F1F;
    padding: 1.042vw 0;
}
.footer.mobile {
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 8vw;
    padding: 8vw 0;
}

#footer-copyright {
    padding-right: 5.2082vw;
}

.footer.mobile #footer-copyright {
    padding-right: 0;
}

.footer .link {
     column-gap: 0.938vw; 
}

.footer.mobile .link {
    column-gap: 3.0534vw;
}

.footer .contact-text {
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-style: normal;
    font-size: 0.9375vw;
    font-weight: 500;
}

.footer.mobile .contact-text {
    font-size: 3.5623vw;
    font-weight: 500;
}