.dataflow-container {
    display: flex;
    flex-direction: column;
    padding-left: 3.595vw;
    padding-right: 3.595vw;
    row-gap: 1.5vw;
}

.anchor-2 {
    padding-top:4.6vw; 
    margin-top: -4.6vw; 
  }

.dataflow-container.mobile {
    padding-top: 10.687vw;
    padding-left: 3.053vw;
    padding-right: 3.053vw;
    row-gap: 8.906vw;
}

.dataflow-container .header.mobile::before {
    right: 1.272vw;
    top: 3.664vw;
    width: 35.369vw;
    background-image: url(../../../public/images/header/Line\ left\ mobile.svg);
}
.dataflow-container .header.mobile::after {
    left: 1.527vw;
    top: 3.664vw;
    width: 35.369vw;
    background-image: url(../../../public/images/header/Line\ right\ mobile.svg);
}


.dataflow {

    /* height: 100vw; */
    border-radius: 0.781vw;
    border: 1px solid #341E1E;
    background: rgba(122, 122, 122, 0.10);
    box-shadow: 0px 9px 8px 0px rgba(0, 0, 0, 0.15);
}

.inner-container {
    margin: 0;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid #341E1E;
    background: rgba(122, 122, 122, 0.10);
    box-shadow: 0px 9px 8px 0px rgba(0, 0, 0, 0.15);
}

.inner-container.desktop {
    padding: 2.94vw 0;
}

.inner-container.mobile {
    padding: 3.94vw 0;
    row-gap: 9.415vw;
}


.dataflow-image {
    padding: 0;
    padding-left: 2.708vw;
    display: flex;
}

.dataflow-image {
    position: relative;
    justify-content: center;
}


.dataflow-image.mobile {
    justify-content: center;
}

.dataflow-red-circle {
    width: 39.8vw;
    height: 39.8vw;
    border-radius: 47.488vw;
    opacity: 0.16;
    background: var(--Red-General, #8C0709);
    /* filter: blur(4.373vw);  */
    filter: blur(3.671vw); 
    /*   4.373vw * 39.869 / 47.488vw   */
    position: absolute;
}
.dataflow-red-circle.desktop {
    /* right: -3.444vw; */
    top: -2.9275vw;
}

.dataflow-red-circle.mobile {
    width: 91.858vw;
    height: 91.858vw;
    border-radius: 91.858vw;
    opacity: 0.16;
    filter: blur(4.373vw);
}

.dataflow-image.desktop img {
    /*40.514vw -1.3*5vw */
    width: 34.014vw;
   
}

.dataflow-image.mobile img {
    width: 72.434vw;
    margin: 10.9vw 0;
}

.dataflow-text {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* 1.823vw -1.3vw*/
    row-gap: 1.135vw; 
    /* -30px, հաշվի առնելով թվի շրջանակի մեծությունը */
}

.dataflow-text.mobile {
    row-gap: 5.089vw;
    padding-bottom: 5.089vw;
}

.dataflow-text.desktop {
    padding-left: 10vw;
    padding-right: 0;
}