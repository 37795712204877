
#page-not-found-container {
    padding: 2vw 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 8.5vw);
    min-height: fit-content;
}

#page-not-found-container-image {
    width: 40vw;
    padding-bottom: 2vw;
}

#page-not-found-container-header {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 2vw;
}

#page-not-found-container-content {
    padding-left: 27.76vw;
    padding-right: 27.76vw;
    padding-bottom: 2.5vw;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#page-not-found-container-button {
    padding: 0.911vw 1.822vw;
    flex-shrink: 0;
    border-radius: 0.521vw;
    border: 0;
    background: #8C0709;
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-size: 0.938vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    transition: 300ms;
}

#page-not-found-container-button:hover {
    background: #6C0008;
}

#page-not-found-container-button:active {
    background: #5D0007;
}

@media (max-width: 991px) {
    #page-not-found-container {
        height: 100vh;
        padding: 10vh 10vw 0;
    }

    #page-not-found-container-image {
        width: 100%;
        padding-bottom: 10vh;
    }

    #page-not-found-container-header {
        font-size: 6.1068vw;
        padding-bottom: 8vw;
    }

    #page-not-found-container-content {
        font-size: 4.0712vw;
        padding-bottom: 8vw;
        padding-left: 3.181vw;
        padding-right: 3.181vw;
    }

    #page-not-found-container-button {
        padding: 4.45vw 8.9vw;
        font-size: 4.58vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
        transition: 300ms;
        border-radius: 2.545vw;
    }

}