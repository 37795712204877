
.feature {
    padding: 0;
    padding-right: 2.291vw;
    padding-right: 2.265vw;

}


.feature-card {
    border-radius: 15px;
    border: 1px solid #341E1E;
    background: rgba(122, 122, 122, 0.10);
    box-shadow: 0px 9px 8px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;

}

.feature-card.desktop {
    height: 11.928vw;
    font-size: 1.25vw;
    font-weight: 500;
}

.feature-card.mobile {
    height: 58.524vw;
    text-align: center;
    font-size: 4.071vw;
    font-weight: 400;
}

.feature-icon.desktop {
    width: 95.398%;
}

.feature-icon.mobile {
    width: 89.655%;
    align-self: center;
}

.feature-text {
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-style: normal;
    line-height: normal;
    padding-left: 1.302vw; 
    padding-right: 1.302vw;
    margin-top: -0.379vw;
}

.feature-text.mobile{
    padding-left: 0.509vw; 
    padding-right: 0.509vw;
}