.navbar {
    height: 4.896vw;
    flex-shrink: 0;
    box-shadow: 0px 4px 10.6px 0px rgba(0, 0, 0, 0.15);
    z-index: 10;
    padding: 0;
    background: #121212;
}

.navbar.mobile {
    visibility: hidden;
    margin-bottom: -4.896vw;
}

a.navbar-brand {
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    position: absolute;
    left: 3.646vw;
}


a.navbar-brand.mobile {
    left: 3.817vw;
    top: 4.326vw;
}

.logo {
    width: 12.187vw;
    height: 2.135vw;
    flex-shrink: 0;
}

.logo.mobile {
    width: 32.316vw;
    height: 5.662vw;
    visibility: visible;
}

#navbarNav {
    justify-content: center;
    
}


.navbar-nav {
    width: 40.677vw;
    justify-content: space-between;
}

.nav-link {
    padding: 0 !important;
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-size: 0.938vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.nav-link:hover.rounded {
    color: var(--system-white, #FFF);
    position: relative;
}

.nav-link:hover.rounded::after {
    content: '';
    position: absolute;
    width: 110.7%;
    height: 16.67%;/* thickness */
    background-color: var(--Red-General, #8C0709);
    top: 100%;/* Position it directly below the text */
    left: -4%;
    border-radius: 1.5px;
    margin-top: 0.26vw; /*  space between text and underline */
}

.nav-link:focus {
    color: var(--Red-General, #8C0709);
    outline: none;
}

/* .nav-link.active {
    color: var(--Red-General, #8C0709);
    outline: none;
} */

