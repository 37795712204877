#privacy-policy-container {
    padding-left: 3.9063vw;
    padding-right: 3.9063vw;

}

#privacy-policy-header.header {
    margin-top: 4.5313vw;
    margin-bottom: 3.2291vw;
    display: flex;
    justify-content: center;
}

#privacy-policy-header.header.mobile {
    margin-top: 10.1781vw;
    margin-bottom: 10.1781vw;
}


#privacy-policy-header.mobile::before {
    right: 1.7811vw;
    top: 3.664vw;
    width: 19.338vw;
    background-image: url(../../../public/images/header/Line\ left\ mobile.svg);
}

#privacy-policy-header.mobile::after {
    left: 1.7811vw;
    top: 3.664vw;
    width: 19.338vw;
    background-image: url(../../../public/images/header/Line\ right\ mobile.svg);
}

#privacy-policy-content {
    padding-left: 1.8228vw;
}

#privacy-policy-content.mobile {
    padding-left: 0;
}

#privacy-policy-content,
#privacy-policy-content h3 {
    /* padding-left: 1.8229vw; */
    color: #FFF;
    text-align: justify;
    font-family: Inter;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#privacy-policy-content.mobile,
#privacy-policy-content.mobile h3 {
    /* padding-left: 1.8229vw; */
    font-size: 4.0712vw;
}

#privacy-policy-content h3 {
    margin-bottom: 1.302vw;
}

#privacy-policy-content.mobile h3 {
    margin-bottom: 7.1246vw;
}

#privacy-policy-content ul {
    padding-left: 0;
    list-style-position: inside;
    margin-bottom: 2.604vw;
}

#privacy-policy-content.mobile ul {
    margin-bottom: 14.2492vw;
}

#privacy-policy-content li {
    margin-left: 0.5208vw;
}

#privacy-policy-content.mobile li {
    margin-left: 2vw;
}

#privacy-policy-container li::marker {
    content: '• ';
}

#privacy-policy-content p {
    margin-top: 1.302vw;
}

#privacy-policy-content.mobile p {
    margin-top: 7.1246vw;
}