.explore-container {
    padding-left: 3.595vw;
    padding-right: 3.595vw;
    display: flex;
    flex-direction: column;
    row-gap: 1.5vw;
    
}

/* navbar-ի հղումները ծիշտ աշխատելու համար */
.anchor-1 {
    /* padding-top: 5.833vw; */
    padding-top: 4.6vw;
    margin-top: -2.5vw;
}

.explore-container.mobile {
    padding-left: 3.053vw;
    padding-right: 3.053vw;
    padding-top: 10.941vw;
    row-gap: 6.107vw;
}


.header {
    font-size: 2.187vw;
    margin: 0;
    width: 100%;
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-self: center;
    white-space: nowrap;
}

.header::before {
    content: '';
    position: relative;
    right: 0.517vw;
    /* for all headers */
    top: 1.3128vw;
    width: 42.604vw;
    /* for all headers */
    height: 1px;
    background-image: url(../../../public/images/header/Line\ left.svg);
    background-size: cover;

}

.header::after {
    content: '';
    position: relative;
    left: 0.517vw;
    /* for all headers */
    top: 1.3128vw;
    width: 42.604vw;
    /* for all headers */
    height: 1px;
    background-image: url(../../../public/images/header/Line\ right.svg);
    background-size: cover;

}

.header.mobile {
    font-size: 6.107vw;
}

.explore-container .header.mobile::before {
    right: 1.272vw;
    top: 3.664vw;
    width: 19.338vw;
    background-image: url(../../../public/images/header/Line\ left\ mobile.svg);
}

.explore-container .header.mobile::after {
    left: 1.272vw;
    top: 3.664vw;
    width: 19.338vw;
    background-image: url(../../../public/images/header/Line\ right\ mobile.svg);
}

.video-box {
    width: 67.113vw;
    /* height: 37.751vw; */
    aspect-ratio: 16/9;
    /* border-radius: 0.521vw; */

    background-color: #1A1A1A;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.video-box.mobile {
    width: 100%;
    /* border-radius: 2.545vw; */
}


.responsive-iframe {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}