.link {
    text-decoration: none;
    display: flex;
    /* column-gap: 0.938vw;  */
}

.link.mobile {
    /* column-gap: 3.053vw; */
}


.icon {
    width: 1.25vw;
    pointer-events: all;
}

.icon.mobile {
    width:6.107vw;
}


 .icon-group a {
    pointer-events: none;
 }





