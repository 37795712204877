.group {
    position: relative;
    padding-right: 2.08vw;
}

.group.mobile {
    padding-right: 0;
}

.inputField {
    border-radius: 0.521vw;
    background: rgba(0, 0, 0, 0.55);
    width: 100%;
    height: 2.917vw;
    border: 0;
    color: transparent;
    font-size: 0.938vw;
    padding: 0;
    padding-left: 0.573vw;
    padding-right: 0.573vw;
    /* padding-top: 0.417vw; */
    padding-top: 0.462vw;
}


.inputField.mobile {
    border-radius: 2.545vw;
    height: 14.249vw;
    font-size: 4.58vw;
    padding-left: 2.799vw;
    padding-top: 2.253vw;
}

.placeholder {
    color: var(--system-white, #FFF);
    font-size: 0.938vw;
    position: absolute;
    top: 0.698vw;
    /* հաշվի առնելով normal line-height -ի 0.2-ը */
    left: 0.573vw;
    pointer-events: none;
    transition: 300ms;
}

.placeholder.mobile {
    color: var(--system-white, #FFF);
    font-size: 4.58vw;
    top: 3.41vw;
    /* հաշվի առնելով normal line-height -ի 0.2-ը */
    left: 2.799vw;
}


.inputField:focus {
    color: #7A7A7A;
    /* padding-top: 0.339vw; */
    outline: none;
    border: 0.052vw solid var(--Red-General, #8C0709);
}

.inputField:focus+.placeholder {
    color: #7A7A7A;
    top: 0;
    font-size: 0.833vw;
}

.inputField.input-present {
    /* padding-top: 0.339vw; */
    color: #7A7A7A;
    -webkit-text-fill-color:  #7A7A7A;
}

.placeholder.input-present {
    color: #7A7A7A;
    top: 0;
    font-size: 0.833vw;
}

.inputField.mobile:focus {
    /* padding-top: 1.654vw; */
    /* border: 0.254vw solid var(--Red-General, #8C0709); */
    font-family: Inter;
    font-size: 4.58vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.inputField.mobile:focus+.placeholder {
    top: 0;
    font-size: 4.071vw;
}

/* .inputField.mobile.input-present { */
    /* padding-top: 1.654vw; */
/* } */

.placeholder.mobile.input-present {
    font-size: 4.071vw;
}

