.feature-container {

    padding-top: 5.833vw;
    display: flex;
    flex-direction: column;
    padding-left: 3.595vw;
    padding-right: 1.276vw;
    row-gap: 2.604vw;

    position: relative;

}

.feature-container.mobile {
    padding-top: 10.941vw;
    row-gap: 7.634vw;
    padding-left: 3.053vw;
}


.feature-container .header.mobile::before {
    right: 0.763vw;
    top: 3.664vw;
    width: 36.132vw;
    background-image: url(../../../public/images/header/Line\ left\ mobile.svg);
}
.feature-container .header.mobile::after {
    left: 1.018vw;
    top: 3.664vw;
    width: 36.132vw;
    background-image: url(../../../public/images/header/Line\ right\ mobile.svg);
}

.features {
    row-gap: 2.24vw;
    margin: 0;
}

.features {
    row-gap: 5.089vw;
}

.mockup.desktop {
    align-self: center;
    width: 91.875vw;
}

.container-fluid {
    padding: 0;
}

.feature-red-circle.desktop {
    width: 54.323vw;
    height: 54.323vw;
    flex-shrink: 0;
    border-radius: 54.323vw;
    opacity: 0.24;
    background: var(--Red-General, #8C0709);
    filter: blur(4.372vw);
    position: absolute;
    top: 11.37vw;
    left: 28.333vw;
    z-index: -10;
}

.feature-red-circle.mobile {
    width: 112.468vw;
    height: 112.468vw;
    flex-shrink: 0;
    border-radius: 112.468vw;
    opacity: 0.24;
    background: var(--Red-General, #8C0709);
    filter: blur(21.361vw);
    position: absolute;
    top: -17.303vw;
    left: 18.066vw;
    z-index: -10;
}