.getInTouch-container {
    padding-top: 8.958vw;
    padding-bottom: 2.3437vw;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 2.969vw; */
    row-gap: 5.104vw;

    position: relative;
}

.getInTouch-container.mobile {
    padding-top: 14.224vw;
    padding-bottom: 8vw;
    row-gap: 9.415vw;
}

.getInTouch-container .header.mobile::before {
    right: 1.018vw;
    top: 3.664vw;
    width: 30.28vw;
    background-image: url(../../../public/images/header/Line\ left\ mobile.svg);
}

.getInTouch-container .header.mobile::after {
    left: 1.272vw;
    top: 3.664vw;
    width: 30.28vw;
    background-image: url(../../../public/images/header/Line\ right\ mobile.svg);
}

.big-logo {
    width: 97.5vw;
    height: 17.083vw;
    flex-shrink: 0;
    opacity: 0.9;

    position: absolute;
    top: 2.031vw;
    z-index: -10;
}

.big-logo.mobile {
    width: 93.893vw;
    height: 16.45vw;

    top: 9.669vw;
}


.heading {
    font-size: 2.188vw;
    margin: 0;
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-self: center;
    align-self: center;
}

.heading.desktop {
    font-size: 2.188vw;
}

.heading.mobile {
    font-size: 6.107vw;
}


.inn-row {
    margin: 0;
    row-gap: 1.3541vw;
}

.inn-row.mobile {
    row-gap: 9.924vw;
}

.form {
    padding: 0;
    padding-left: 3.595vw;
    padding-right: 1.515vw;
    /*3.595vw - 2.08vw  */
    display: flex;
    flex-direction: column;
    row-gap: 2.08vw;

    position: relative;
}

.form.mobile {
    padding-left: 3.308vw;
    padding-right: 3.308vw;
    row-gap: 6.616vw;
}

.input-group {
    display: flex;
}

.input-group.mobile {
    flex-direction: column;
    row-gap: 6.616vw;
}

.submit-button {
    width: 7.031vw;
    height: 2.917vw;
    flex-shrink: 0;
    border-radius: 0.521vw;
    border: 0;
    background: #8C0709;
    align-self: center;
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-size: 0.938vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    transition: 300ms;
    translate: -1.04vw 0;
}

.submit-button.mobile {
    width: 34.351vw;
    height: 14.249vw;
    border-radius: 2.545vw;
    font-size: 4.071vw;
}

.submit-button.desktop:hover {
    /* on mobile hover doesnt work properly */
    background: #6C0008;
}

.submit-button.desktop:active {
    background: #5D0007;
}

.submit-button.mobile:active {
    background: #5D0007;
}

.contact {
    padding-right: 3.595vw;
    display: flex;
    flex-direction: column;
    /* row-gap: 1.98vw; */
    row-gap: 1.7968vw;
    font-size: 1.25vw;
    align-items: center;
}

.contact.desktop {
    padding-left: 3.75vw;
}


.contact.mobile {
    font-size: 5.089vw;
    row-gap: 7.888vw;
}

.contact .bottom {
    display: flex;
    flex-direction: column;
    row-gap: 1.98vw;
}

.contact.mobile .bottom {
    align-items: center;
    margin-top: -1.781vw;
    /* font-size: 5.089vw; */
    row-gap: 4.835vw;
}


.contact-text {
    color: var(--system-white, #FFF);
    font-family: Inter;
    font-style: normal;
    font-size: 75%;
    font-weight: 500;
}

.contact-text.mobile {
    font-size: 80%;
}


.head {
    font-size: 1.25vw;
    line-height: normal;
    display: flex;
}

.head p {
    margin: 0;
}

.head.mobile {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    font-size: 5.089vw;
}

.decore.desktop {
    display: none;
}

.decore.mobile {
    width: 90.076vw;
    height: 0.255vw;
    background-color: #282828;
}


.copyright {
    /* text-align: center; */
    margin: 0;
    line-height: 160%;
}

.icon-group {
    display: flex;
    column-gap: 0.938vw;
}

.icon-group.mobile {
    column-gap: 3.053vw;
}


#text-message-container {
    /* 1.172 + 0.09375, see line 239 */
    padding-top: 1.26575vw;
    padding-bottom: 0.573vw;
    min-height: 6.25vw;
    cursor: text;

}

#text-message-container.mobile {
    /* 5.725 + 0.458, see line 239 */
    padding-top: 6.183vw;
    padding-bottom: 2.799vw;
    min-height: 30.534vw;
}

#text-message {
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    resize: none;
    overflow: hidden;
    border: none;
    color: #7A7A7A;
    line-height: 110%;
    /* -10% from normal*/
}
#text-message:focus+label {
    color: #7A7A7A;
    top: 0;
    font-size: 0.833vw;
}

#text-message.mobile:focus+.placeholder {
    top: 0;
    font-size: 4.071vw;
}
#text-message.mobile {
    padding: 0;
    width: 100%;
    height: 100%;
    resize: none;
    overflow: hidden;
    border: none;
    color: #7A7A7A;
}

#text-message:focus {
    outline: none;
}

.validity-message {
    color: var(--Red-General, #8C0709);
    font-family: Inter;
    font-size: 0.938vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    position: absolute;
    top: 2.917vw;
    padding-left: 0.573vw;

    display: none;
    transition: 300ms;
}

.validity-message-textarea {
    top: 6.25vw;
}

.validity-message.mobile {
    font-size: 4.071vw;

    top: 14.249vw;
    padding-left: 2.799vw;
}

.validity-message-textarea.mobile {
    top: 30.534vw;
}

#privacy-policy {
    display: flex;
    column-gap: 0.8333vw;
}

#privacy-policy a {
    color: #FFF;
    font-family: Inter;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

#privacy-policy #division {
    display: inline-block;
    width: 0.052vw;
    height: 1.0417vw;
    flex-shrink: 0;
    background: #D9D9D9;
}

@media screen and (max-width: 991px) {

    #privacy-policy {
        column-gap: 3vw;
    }

    #privacy-policy a {
        font-size: 4.0712vw;
    }

    #privacy-policy #division {
        display: inline-block;
        width: 0.225vw;
        height: 4.523vw;
        flex-shrink: 0;
        background: #D9D9D9;
    }

}