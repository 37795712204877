.store-button {
    padding: 0.677vw 0.99vw;
    flex-shrink: 0;
    border-radius: 0.781vw;
    border: 0.052vw solid #341E1E;
    background: rgba(122, 122, 122, 0.30);
    box-shadow: 0px 9px 8px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    column-gap: 0.781vw;
    align-items: center;
    justify-content: space-evenly;  
    transition: 300ms;
    text-decoration: none;
}

.store-button.desktop:hover { /* on mobile hover doesnt work properly */
    background: rgba(122, 122, 122, 0.20);
    box-shadow: 0px 12px 8px 0px rgba(0, 0, 0, 0.35);
}

.store-button.desktop:active {
    background: rgba(122, 122, 122, 0.10);
    box-shadow: 0px 12px 8px 0px rgba(0, 0, 0, 0.35);
}



.store-button.mobile {
    padding: 3.308vw 5.343vw;
    column-gap: 3.308vw;
    border-radius: 3.817vw;
    border: 0.255vw solid #341E1E;
    column-gap: 3.308vw;
    
}
.store-button.mobile:active {
    background: rgba(122, 122, 122, 0.10);
    box-shadow: 0px 12px 8px 0px rgba(0, 0, 0, 0.35);
}
.store-icon {
    width: 0.833vw;
    height: 0.833vw;
}
.store-icon.mobile {
    width: 12.977vw;
    height: 12.977vw;
}

.hidden {
    display: none;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.download{
    display: flex;
    align-items: center;
    column-gap: 0.26vw;
}

.download-text {
    color: var(--system-white, #FFF);
    text-align: justify;
    font-family: Inter;
    font-size: 0.937vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.download-text.mobile {
    font-size: 4.071vw;
}

.download-text.store {
    font-size: 1.614vw;
}

.download-text.store.mobile{
    font-size: 7.125vw;

}

.qr {
    border-radius:  0.39vw;;
    width: 5.208vw;
    height: 5.208vw;
}
.qr.mobile {
    display: none;
}