.banner {
    width: 100%;
    height: 50.365vw;
    /* background-image:url("../../../public/images/Banner/banner-desktop.webp"); */
    background-image: url("../../../public/images/Banner/Banner.webp");
    background-size: cover;
}

.banner.desktop {
    /* margin-top: -0.677vw; */
    /* changed */
    margin-top: -2.1875vw;
}

.banner.mobile {
    position: relative;
    /* height: 188.5vw; *//* changed */
    height: 100vh;
    min-height: 177vw;
    max-height: 188.5vw;
    /*  */
    background-image: url("../../../public/images/Banner/Banner-mobile.webp");
    background-size: contain;
    background-repeat: no-repeat;
}

.button-container {
    position: absolute;
    left: 50vw;
    /* top: 35.5vw; *//* changed */
    top: 34.6355vw;
    display: flex;
    flex-direction: column;
    row-gap: 1.2vw;
}


.button-container.mobile {
    width: 100%;
    /* top: 98.219vw; */ /* changed */
    top: unset;
    bottom: 5vh;
    /*  */
    left: 0;
    align-items: center;
    row-gap: 7vw;
}

.introduction {
    width: 45.365vw;
    margin-bottom: 0;

    color: var(--system-white, #FFF);
    text-align: justify;
    font-family: Inter;
    font-size: 0.937vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    position: relative;
    padding-left: 0.885vw;
}

.introduction::before {
    content: '';
    position: absolute;
    left: 0.312vw;
    top: 0;
    bottom: 0;
    width: 0.313vw;
    border-radius: 0.156vw;
    background: var(--Red-General, #8C0709);
}

.introduction.mobile {
    width: 89.567vw;
    padding-left: 3.053vw;
    font-size: 4.071vw;
}


.buttons {
    display: flex;
    column-gap: 1.667vw;
}

.buttons.mobile {
    flex-direction: column;
    row-gap: 3.180vw;
}